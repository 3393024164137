import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import NavButtons from 'components/Software/Windows/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Windows Software for your INSTAR IP Camera",
  "path": "/Software/Windows/YAW_Cam/",
  "dateChanged": "2017-12-05",
  "author": "Mike Polinowski",
  "excerpt": "The Windows app YAW Cam for your PC or Laptop. Control your camera from your computer and harness the computing power for advanced features.",
  "image": "./P_SearchThumb_YAW_Cam.png",
  "social": "/images/Search/P_SearchThumb_YAW_Cam.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-Windows-Yaw-Cam_white.webp",
  "chapter": "Software"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='YAW Cam' dateChanged='2017-12-05' author='Mike Polinowski' tag='INSTAR IP Camera' description='The Windows app YAW Cam for your PC or Laptop. Control your camera from your computer and harness the computing power for advanced features.' image='/images/Search/P_SearchThumb_YAW_Cam.png' twitter='/images/Search/P_SearchThumb_YAW_Cam.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Software/Windows/YAW_Cam/' locationFR='/fr/Software/Windows/YAW_Cam/' crumbLabel="YAW Cam" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "software",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#software",
        "aria-label": "software permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Software`}</h1>
    <h2 {...{
      "id": "windows",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#windows",
        "aria-label": "windows permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Windows`}</h2>
    <h3 {...{
      "id": "yet-another-webcam-viewer",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#yet-another-webcam-viewer",
        "aria-label": "yet another webcam viewer permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Yet Another Webcam Viewer`}</h3>
    <p>{`Yet Another Webcam Software? Maybe. But Yawcam delivers a punch in a small package. Yawcam is a webcam software for windows written in java. The main ideas for Yawcam are to keep it simple and easy to use but to include all the usual features. And Yawcam is completely free to use.`}</p>
    <p>{`In order to integrate your INSTAR IP camera in the `}<a href="http://www.yawcam.com/" target="_blank" rel="noopener noreferrer">{`YAW Cam`}</a>{` we recommend you to download the latest version from `}<a href="http://www.yawcam.com/download.php" target="_blank" rel="noopener noreferrer">{`here`}</a>{`.`}</p>
    <h3 {...{
      "id": "add-a-camera",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#add-a-camera",
        "aria-label": "add a camera permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Add a Camera`}</h3>
    <p>{`Start the software and go to `}<strong parentName="p">{`Settings / Device / Change to / IP Camera`}</strong>{`. Now type in the address for your local camera. The video path would be:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`VGA Video Path: http://192.168.1.237:80?user=admin&pwd=`}</code></pre></div>
    <p>{`for a camera with the default login `}<em parentName="p">{`user = admin`}</em>{`, `}<em parentName="p">{`password = `}</em>{`[empty]`}<em parentName="p">{`, the _local IP address 192.168.1.237`}</em>{` and the `}<em parentName="p">{`HTTP port 80`}</em>{`. Please adjust the path if you changed the default login or the default web port accordingly. Usually you should be able to just type in the IP address and use the login mask below for your username and password - but this did not seem to work for me.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "515px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/2a6bc8e8263d44eea83ab4c624952deb/fbdcb/YAWcam_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "53.043478260869556%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7EAAAOxAGVKw4bAAADLElEQVQozz3PW2/bZACH8TcCaVURF9XE8YIbPgJc0Ws+AGJTNTapmsQQmbYKKJMADYnRMZVejLJdDE2jQqVb03VpaUiPS9J4Tdc08XJy7Ng52E5a55y2OdCktvP+UYvEF/jpeciKL2ba1iKY+Dt4NLkSNR4sBPR7Tzb1h2ucOc8kMOPhqX3OQ+33p7DMvDgcmWZ1MuDAy4NL1HJhEZbzTljOOWAZ+AuWM09AJLVyOyYo4BOyzgRFeufhKp2Y36CbbIJuBuLgEipNxMXurqJBrR5+OepIPe6zukEGXSYZfApyYRXk3DLIwCLIWQdIXNkf8gSSWH0W1R2eMO7bVvDHnBtT827YXSz1bkUp4/NTfygO5jlbiQtS81tbHKevMvTdaxt45ysGbw+t483LbrxlXQMJS8VrhWoTB822nlI0+iIcRVyUEApHaUZW4PLzdGL2KX3k9GHeG2vbvZw+veSHzeGhM04GixsxuAIivKE03EEJREgXhztHBgDo5eoe2K0gfe5apzFeQqFYpfnSAW139G4yW4Kglj+6Oivfu7PMI8lHzO0Aiy2/HzyfgKKoKBRLIEE+P9z85+gEbHY6SG34THHBaSTzB/CHRKpVGhSAmcqWEFHy75/5U7k1vsxjTxWMspZDRVNR2pFRyGXQqBVBWD4/3GrrAKheq9dRiEW76rrXUMoNRHmZFqoNCkrN48JgUuu/ZM+OffibgEvTovH5XA7W2Sysj3O4bMvisyn5uFA7AU1D1/caDWjRSFfxeA25VEeEz9BCtUmB/8BtcfcD60Lu51dHU3hpRDbIdQnkmwTIMA/yRRzkCney/HXrUEer1dTLe/tQAsETMFOsI8Slab7S/L/Qn9jpv7JYGHv9VwWnb8tG35iMnpsKen+U0ft9Gr3fJUGC3O5Qu6OjsV/T95t15NmAKS8tGWq5jjCXpMXjZVBTUvLwcep7VufOT6/dLeKV8ZbRN17DG+NpnBpJoeeGip7rGZBtofyLoFTBJTUjmtIQDks0FBC6m/EduAMSZcUCTe/WukwohWdpXHzEPpj8ZPZT9P8+an488wPOT55F700Wp25V0XMjh38BA1qx0BwIxk8AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2a6bc8e8263d44eea83ab4c624952deb/e4706/YAWcam_01.avif 230w", "/en/static/2a6bc8e8263d44eea83ab4c624952deb/d1af7/YAWcam_01.avif 460w", "/en/static/2a6bc8e8263d44eea83ab4c624952deb/d8883/YAWcam_01.avif 515w"],
              "sizes": "(max-width: 515px) 100vw, 515px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/2a6bc8e8263d44eea83ab4c624952deb/a0b58/YAWcam_01.webp 230w", "/en/static/2a6bc8e8263d44eea83ab4c624952deb/bc10c/YAWcam_01.webp 460w", "/en/static/2a6bc8e8263d44eea83ab4c624952deb/92849/YAWcam_01.webp 515w"],
              "sizes": "(max-width: 515px) 100vw, 515px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2a6bc8e8263d44eea83ab4c624952deb/81c8e/YAWcam_01.png 230w", "/en/static/2a6bc8e8263d44eea83ab4c624952deb/08a84/YAWcam_01.png 460w", "/en/static/2a6bc8e8263d44eea83ab4c624952deb/fbdcb/YAWcam_01.png 515w"],
              "sizes": "(max-width: 515px) 100vw, 515px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/2a6bc8e8263d44eea83ab4c624952deb/fbdcb/YAWcam_01.png",
              "alt": "Windows YAW Cam",
              "title": "Windows YAW Cam",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Open the dialogue to add a new IP camera.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "468px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/b4db59d56dae699e478b0dd93cdbb949/90372/YAWcam_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACd0lEQVQ4y42RS2/TQBSF88P5BWxBiMeiiA0bBCoSoiyRkBCQR9vESRx74jh2/J47M7brpK0zB43TVhUvsfj0HVueI9+5vdcff8yfH/f9J2+/s6fHffb4zVf32fvh6ujTdH10YvlHJ9b6L/gvP1nRow8zPHw3wYtnb/WDh6/QO19stgOLoT9ZYmB5+HHuYMJiOCFhEfyB8IC95h0s5NoNiv36dIQvn796vVWQFIuZBdex9/Zsqp2FrXmeaUHFXvBibyyJ75XgXSaed1RK7utS7atSoixVWzUXWPLtt567TivXYWCerz1vrf11qBcO03PbgbHLPMzmC5hn23YwnS3gMA8kFKSqIFRp3AohYYd82GNBsh0NBhj0h3oynmA0HGE0PMXpyHCG8XgM5i7heR4YWyLaRFBKgYju00pBWGVq0Fsl1BR5hjRNdZIkiKMYneMYURR1FkJAStlhsikxvoWEaJUQcGNhCkUTBj4YY5pzQlnVkN0YJVRZdSYhIaQ0B1FwAieC+faWglMriGBvbgpX/hrnY0u7LoPrulguPaxWPhzHhe/7N9lBEIRdKef8V1q6K0xlUxQcUZzozWaDNMtBZA4JmPeHTMhNFhJVXXdT3Keq6m4p8w0dCvM0QRiG2txXfbHD7qrF9vIau8vrO++uDrnZXf3GdnfdCqkwD01hIps8yxDFsU7SDLKsUNXN/3Fx59b8/U2haMymOAnN6XDpBef3/CuH8TvyAnlRdEshzmGbkYNMNrUiKElaSUIpxd9RxoQkI0ydAEHMwfwUZ5bbekF6WIoVyMaPCV4ktBcZ/5tVJDD3CwztGJaX48xN0Z8G7ZilGHg0+AkwkwCTChZ7KQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b4db59d56dae699e478b0dd93cdbb949/e4706/YAWcam_02.avif 230w", "/en/static/b4db59d56dae699e478b0dd93cdbb949/d1af7/YAWcam_02.avif 460w", "/en/static/b4db59d56dae699e478b0dd93cdbb949/4fabd/YAWcam_02.avif 468w"],
              "sizes": "(max-width: 468px) 100vw, 468px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/b4db59d56dae699e478b0dd93cdbb949/a0b58/YAWcam_02.webp 230w", "/en/static/b4db59d56dae699e478b0dd93cdbb949/bc10c/YAWcam_02.webp 460w", "/en/static/b4db59d56dae699e478b0dd93cdbb949/0799c/YAWcam_02.webp 468w"],
              "sizes": "(max-width: 468px) 100vw, 468px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b4db59d56dae699e478b0dd93cdbb949/81c8e/YAWcam_02.png 230w", "/en/static/b4db59d56dae699e478b0dd93cdbb949/08a84/YAWcam_02.png 460w", "/en/static/b4db59d56dae699e478b0dd93cdbb949/90372/YAWcam_02.png 468w"],
              "sizes": "(max-width: 468px) 100vw, 468px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/b4db59d56dae699e478b0dd93cdbb949/90372/YAWcam_02.png",
              "alt": "Windows YAW Cam",
              "title": "Windows YAW Cam",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Add a new IP camera and type in the video path + login credentials.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "657px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/aa901c9339254d1334d732df893f6079/a1253/YAWcam_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "78.26086956521739%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAIAAACZeshMAAAACXBIWXMAAAsTAAALEwEAmpwYAAADxUlEQVQoz23Na0xadxjH8b+oFBU4yikHncDhItCjHqAoKouGRG3xgs5KIbUbk0gsMQ7jOiW8GTmLTWtTskvmmqVubkF70WyK2qgMhbg6vDVaqK11JmXZYmsXTRZebshZbLN3++Z587z45QOmA+v3gw+ngw+nFtZ9SxHfUuSXR8+3ooehcDS4/iywuh1Y215YeTq//MQf2vop9HhuKTzhW/5xZmnKvwYKlcVcLpfHR1GBEOFkn2QjFy60zMzO3bjhbr90qeXiRUOzQaerKS3T4Lgcx3Euj89gMFJTqUXqMlDfeI6VlQlBEAzDaWlp6enpCoXio8uXXS5Xl91usbQajUaTyYTlYzDMwjCMw+FQkpMBALhcCd5pNsKsLAiCEARhMplUKlUqlbpcLo/HMzg42NzcrFAoLBYLjuMoiorFYhiGaTRaakqKUlUMRFIsCYBUKhWG4dzcXCaTyeVyHQ6H2+0mCEKv1+fk5JSUlKhUKrlcjqJoxusgiFlZfRbk40qEzX7zi0QiBoOBIOza2hqTyVhZVaVWFyuVSoPBoNfri4qKBAIBh8NhMBgnqFRVcQmQYIUymYyNIArladmpU5lZrMwslqqkrPLM2YqKira2tqamJq1WW15ejuO4RCLh8/lqtVqjKTsef/hx//WvR78cmRsYmflsyPvFd5NXb967ddPT/0n/OYPBeP682WxuaWnp7e11OBw2m62jo8NqtdbW1Lz73vtg6I73+W8vIk92Nx/vLm/sbmxFo7/vH+wfTN735/B41VVVAwMDoVBobGwsEAiEw+Hx8XGtVsug092ffg7Gpua3t3c2NsNPd6JrkWhk54+X+3/+HSeH7nkBhWI2m/uuXPH5fARBuN3u0dFRm83G4/EyMjLqGxqBd27x5d7e3t6LV/uvYrG/YrHY4cEhSZLfj06C5BRLa6vT6QwGgwRB9PX1DQ8P19XV5RyXXa9vBLPBVZIk4/F4IpE4Oi4Rj8dJkrw18gNfILx27arT6fR4PO3t7Xa7nSAIqVQqEAgBAPkFOJieD5EkmUgkyP86OjoiSfKb2xOoSHy9v7+np8fpdDY0NGg0Gp1OB8PwCRqNkpRUgCvA+OzisXxMJt7cP6/lb+9OZr+Va//A3tXV1d3d3dnZabVaS0tLIQhisVjJFAoqFAPf4vr/yncm5ugQpJDLMQyTyWR5eXkymQxFURqNRqfTkylJqFAEPF7//GrEvxL2r0QW1rZ+3nj2YHPnwaNfvxrx6ur11Wd0b5dXFMoVojypWCLFCgp5qDCXxz/JRk4Xl/0LfhmaBHbj68cAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/aa901c9339254d1334d732df893f6079/e4706/YAWcam_03.avif 230w", "/en/static/aa901c9339254d1334d732df893f6079/d1af7/YAWcam_03.avif 460w", "/en/static/aa901c9339254d1334d732df893f6079/b5dc5/YAWcam_03.avif 657w"],
              "sizes": "(max-width: 657px) 100vw, 657px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/aa901c9339254d1334d732df893f6079/a0b58/YAWcam_03.webp 230w", "/en/static/aa901c9339254d1334d732df893f6079/bc10c/YAWcam_03.webp 460w", "/en/static/aa901c9339254d1334d732df893f6079/b9f96/YAWcam_03.webp 657w"],
              "sizes": "(max-width: 657px) 100vw, 657px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/aa901c9339254d1334d732df893f6079/81c8e/YAWcam_03.png 230w", "/en/static/aa901c9339254d1334d732df893f6079/08a84/YAWcam_03.png 460w", "/en/static/aa901c9339254d1334d732df893f6079/a1253/YAWcam_03.png 657w"],
              "sizes": "(max-width: 657px) 100vw, 657px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/aa901c9339254d1334d732df893f6079/a1253/YAWcam_03.png",
              "alt": "Windows YAW Cam",
              "title": "Windows YAW Cam",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Confirm the camera address to start the video stream.`}</p>
    <h3 {...{
      "id": "advanced-settings",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#advanced-settings",
        "aria-label": "advanced settings permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Advanced Settings`}</h3>
    <p>{`Yawcam offers you a surprisingly wide variety of functions for such a small program - motion detection, email notification, ftp alarm upload and a web server to stream your cameras video to the internet. The following screenshots just give you a small overview over the available settings. Please refer to the Yawcam website for detailed information on every available function.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "654px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/bcf74914a337bf346f9cfd9cccdf0da6/68e9c/YAWcam_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "79.13043478260869%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAIAAACZeshMAAAACXBIWXMAAAsTAAALEwEAmpwYAAADeklEQVQoz0WR608cVQDF518y8YOJhqSpGFtSG2MxaX3VUNsEE1oNodXWqoWCILaEmFhjY9OGBBuBFcLD3Vl267JSYBFCFygLuzt35t6Znblz77z3Ma8dAzUx+X395Zycw8ywy1N/LsWOmEutzi6upFfy6zt8JrebXN5is5uJ7EY888/Ck/X59Np8OjfDLv82k3o8l4kvPWMudl3ruPzVx1033u347Nylng87vxh9MJXbqzyKpb6/N3579GHvyIMbg/d6ekc/v/nD5etDF7v7LnT2nHjj1On295jk03x6bW9po7RRkPJA3yiqk4nV8bnsRHxlMrE6ya5NsmtTyVzskPVYMjeV2mSTy7/+dL/1rTNMdm1TVamhUdvUoygsPN++Ozw4cLu3v+/WYH/f0MD/DB4x0Hdr5M7w/bHxE6fbmdTSquM2BSTLsuy6rqZppmlR3agQo+EFQRj5Qej5oesFru8HYeh6fhRFbDrzztmPGDaz6vnN/TJSMI2iCGMMAODKHM8LEFWKQCrzFV7EACqAhxCJQICGrk8vJFrb3mbiT1aiKCoIaomXX8hYUSCEsiwLEImiRCi1bFvTNEkSCdVkrHpuY3qebWltY9a3i54fQlkjhARBqKrqi3CIEC9ARaWWXSWECgLEimLbtmEYjXo1ns5+cKGT2SlJTrVeBFKlIkfNo2SsInS4AYQiQpJpO3a1pummjDWxoooVYllO5mnux18eMs8OkBeESNYopc1mE2NMCBEEQRRFCBGhmqYblFKs0hIvPy8KhbJoWrXZ+OLZ85cOZd1ulAHEWKnX64QQAHjAcQAAnuct21EwFkWRUmpbpmWZhqG7jdpC8q9TZ879J5cA5MqcilVFUUqcwAMOQQEhiKAgACAiUUQiOapBCPXcRmwu3vJ6G5M/QE7dI9QwDcP3fYxxWajsFsqFItg/AHsHgAMCQkiSJIyxYRhU07xG44/5xGvHTzJbBdgM/GYYhEHQDAOsyCUObeb3t3YONrZ2twucrJDDJ4hKjp6QZbledSamZ196pYXJl2Sn7pp21arWTKemGZaq6ZppGrZj1xp2rW7YVdOp27WGoldLksErFsT2TGrl5VePMY8XMo9i8bFpdmyaHZ9dnEgsTbF/TyayP4/93js8+vV3d74dGrn6Tf+Vazc7u69/cuXL8592d3RdPdn+fsvxN/8F0GQBNvQylk4AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bcf74914a337bf346f9cfd9cccdf0da6/e4706/YAWcam_05.avif 230w", "/en/static/bcf74914a337bf346f9cfd9cccdf0da6/d1af7/YAWcam_05.avif 460w", "/en/static/bcf74914a337bf346f9cfd9cccdf0da6/f8b48/YAWcam_05.avif 654w"],
              "sizes": "(max-width: 654px) 100vw, 654px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/bcf74914a337bf346f9cfd9cccdf0da6/a0b58/YAWcam_05.webp 230w", "/en/static/bcf74914a337bf346f9cfd9cccdf0da6/bc10c/YAWcam_05.webp 460w", "/en/static/bcf74914a337bf346f9cfd9cccdf0da6/d7085/YAWcam_05.webp 654w"],
              "sizes": "(max-width: 654px) 100vw, 654px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bcf74914a337bf346f9cfd9cccdf0da6/81c8e/YAWcam_05.png 230w", "/en/static/bcf74914a337bf346f9cfd9cccdf0da6/08a84/YAWcam_05.png 460w", "/en/static/bcf74914a337bf346f9cfd9cccdf0da6/68e9c/YAWcam_05.png 654w"],
              "sizes": "(max-width: 654px) 100vw, 654px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/bcf74914a337bf346f9cfd9cccdf0da6/68e9c/YAWcam_05.png",
              "alt": "Windows YAW Cam",
              "title": "Windows YAW Cam",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Let the software upload snapshots to an FTP server in case of an alarm event.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "658px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/263817a182865814d07e761adebbffa5/889a4/YAWcam_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "78.26086956521739%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAIAAACZeshMAAAACXBIWXMAAAsTAAALEwEAmpwYAAADRUlEQVQoz22Q709bVRjH71+0Vy5xmkg0I2YYsy3ZTJyvNMaYGIwvcMvCsqDGLTA3cY7VAmOjtB22lkIJg9Iio+26spVsBfpz5d72/jzn3nPuPa20t73l9i6lJGr0m8+LJ0/yzff5PpTDuzQ1G7jnnp9yLzh9yw7vo5VIcqcohuMvA6H4fDDqX4n+sbQ+uxB+6F91+YPTvy/emfLcue9z+0NU/6Xv+wdHvro8/NnXV7/49lr/4E93XY8iKdYZiN5+ELg16bsx7rk+5v5udPrqzXtXRiYGfrx7cXD4k3MXenpPUYlt+tkOs1MEe1KFRY1UETrnQpMPFx94lx2+4IxvdWZu1ekPuTqE3fNh18L6nH/F4/S813eWeprclrGGFEXDyGofFHIZ18y03TY2brdN/Idxu81uG7s/NTHrD/R+cJaKP09V6y2mxImCZBgthJCmaQrCpPpXq3XQaLb+gdE0WnqjaVnW0+SL0+cvUNHNlNEyaQ429LplWTKEHMvxHCeKogTkMg+7MJxUYnlBBLwgqipeizx552QfFdl8aVlWloGZV7xlWRBChBAAEACIMSZEI0SrENKFEIJVtaHX/4wl3n73fWqnwDWNVrEMShwwTVNRlDJTSu+mc7lcuVRmaAYAoGkaVlVV07pDvbb/5PmLT7/sp7KMVNebDAc1VW23LVmWeZ4vl1mB5wVeoGmaYRiWZWVZrlQqR+b9/cRWatQ2SWVosdky91gAIewmCzyfz+UL+UI+XyjkC7lstsSUAACSKCKEVFXV67X1WOLDcx9TWVpSqzonSJ2triOEOI5L76az2VwmncnnCzwvCIIgSRLPcUfmWi2SSJ468xGVoSVc1Ys0y9D04avAUTtCDjtqlUMRQo7Oxrih18OR+Ime3k5yvWEgrBGNGIYBITw0/i3t3+qa1yLxN3tOUpk90TIP2u221W6bpgkhQEjBCP0vKsaKojT1Wngjduz4W9QuDSrVfaRVMOkgY1WCiiR3AAqCCoYKBgqWMS6J8vYrIcuAzJ649PjZsTdOUP61Tc9K1BuMeYMxXygeeJxc3Nha3NhyzC3/Mum8+dv0z+OOa7ftQzd+vXJ99NIPtwaGRgaGhj//5nLfmfOvAZPTBsYYmD2rAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/263817a182865814d07e761adebbffa5/e4706/YAWcam_06.avif 230w", "/en/static/263817a182865814d07e761adebbffa5/d1af7/YAWcam_06.avif 460w", "/en/static/263817a182865814d07e761adebbffa5/09bf4/YAWcam_06.avif 658w"],
              "sizes": "(max-width: 658px) 100vw, 658px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/263817a182865814d07e761adebbffa5/a0b58/YAWcam_06.webp 230w", "/en/static/263817a182865814d07e761adebbffa5/bc10c/YAWcam_06.webp 460w", "/en/static/263817a182865814d07e761adebbffa5/6ad61/YAWcam_06.webp 658w"],
              "sizes": "(max-width: 658px) 100vw, 658px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/263817a182865814d07e761adebbffa5/81c8e/YAWcam_06.png 230w", "/en/static/263817a182865814d07e761adebbffa5/08a84/YAWcam_06.png 460w", "/en/static/263817a182865814d07e761adebbffa5/889a4/YAWcam_06.png 658w"],
              "sizes": "(max-width: 658px) 100vw, 658px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/263817a182865814d07e761adebbffa5/889a4/YAWcam_06.png",
              "alt": "Windows YAW Cam",
              "title": "Windows YAW Cam",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Activate a webserver to stream your cameras live video to the internet - don´t forget to forward the port to your workstation!`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "656px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/671e1abb95eb32278fa0adb71617c3b3/748f4/YAWcam_08.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "77.82608695652173%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAIAAACZeshMAAAACXBIWXMAAAsTAAALEwEAmpwYAAADmUlEQVQozzWQyU8bZwDFh0uu4Zj8FW3PVatKOTRqpV6KlKUqSxaqtFJDQwooidwiGtKUQEKowRgaiFlssOuEzdSkpMQhJagGFAXwNjPfLPZ49m82e7zOVC7q009P7/L0pIe4Pf4J78KYJ+CZX570LfoXnu0eEn/vxhfCL4OhjcDyc//iujcYng6EpuZXHs8tOce990dnxqeCA9MbSJtj4KrjQWvHna86fv76xr3bTt+fURB+nXL7150zfzz0rNx/tHDXHbg97OsZmv3hgaez1+W42fvuO++d+aYb2YvT22+SIKtQvE6LZuivbe/TtbHp4IRv8YjJuaXH88se/4rHvzL1++qjmSdbL7aaWi591tKFyIoOVT1vaJZVkUXhSTAw75t1Df/qHBp0u4bH3a6x0ZFx94jbNex2DY+NjgwNDqxtRFpar9TKuUI1hlJYCtV0wzByoiRns6wgCCzH5U2zXC4XS+VCqVypVi3Lqlar1UrFtu229u8brnQjxXI1w8kcL9q2rWkaIEgMUEmUiCWwWAI9OIjHEtnXuxTDqBwnZrJ8OsOq+VLzpdZPmrsQ27Yl3SQoxrZtXdMBmSYpGsUADgiSSmMARJN7b4j9f5I7CQwlCBrDgZIrN164fLqpC9FzhawA05lsuVQyDAPFiHgsBgAgSEJkxSgWrV+ur39Wf2zp2Gp8VWEVAHBByd3q7vmivQ9R9XyGhySVtixL13WCTGMYdnh4GEvEaEBH9iN1vrq6YB0yiwT3ggzOJNFkhofTc/4bQwEkb5Y4WWeyXKVSMXQDA2QikQAAP1rewXZOhE6cXD95fOV4OB6GLMQBLmlm2/WOs9/9gmi5IkowFEGoqqppGkWzaTqLYjgOCIKkMADepg5iIPE2dZDCsNqdOJD14rfXrn962YFoRgGleEDQnCDk5NwrcisEwlgckHSa44T9/RhNMbKsSpIiipAXJI4TcsXKly0XP75wCzELpRQtbO6ksoJiaDrFUgzMQBlCCBVFkSUJQln5XxAqsiSaZetcY/Oppi7ENAu2bVmWZdu2qiqZdEbiRY5juZpY/j/n2BoCz/G1zJjFcsOZ8x+ea0c0I6/pxhGKqomyLMlQkiFUVKhqiqpBVVN1A2oGSgk4Aw/RtJC3G843fnD2GjK3+jIQ3vSHNwNrr54+316O7Cy9iAbCEeeEt9812e+a6HP+9mPfkOPuYGdPf0fPvas3f+rsffj+R6c+b+v7F2565hEympfOAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/671e1abb95eb32278fa0adb71617c3b3/e4706/YAWcam_08.avif 230w", "/en/static/671e1abb95eb32278fa0adb71617c3b3/d1af7/YAWcam_08.avif 460w", "/en/static/671e1abb95eb32278fa0adb71617c3b3/0ec20/YAWcam_08.avif 656w"],
              "sizes": "(max-width: 656px) 100vw, 656px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/671e1abb95eb32278fa0adb71617c3b3/a0b58/YAWcam_08.webp 230w", "/en/static/671e1abb95eb32278fa0adb71617c3b3/bc10c/YAWcam_08.webp 460w", "/en/static/671e1abb95eb32278fa0adb71617c3b3/31099/YAWcam_08.webp 656w"],
              "sizes": "(max-width: 656px) 100vw, 656px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/671e1abb95eb32278fa0adb71617c3b3/81c8e/YAWcam_08.png 230w", "/en/static/671e1abb95eb32278fa0adb71617c3b3/08a84/YAWcam_08.png 460w", "/en/static/671e1abb95eb32278fa0adb71617c3b3/748f4/YAWcam_08.png 656w"],
              "sizes": "(max-width: 656px) 100vw, 656px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/671e1abb95eb32278fa0adb71617c3b3/748f4/YAWcam_08.png",
              "alt": "Windows YAW Cam",
              "title": "Windows YAW Cam",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Use the scheduler to automate the motion detection, web streaming, video recording or an FTP upload to generate a timelapse video.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "701px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/8fc57039a191eb41830cdca16580c456/49217/YAWcam_09.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.52173913043478%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC1UlEQVQozy2OS2hTWRzGD44bZ5Za4jhVY1DbmlZoTYgLH1TEUF2EMqGLbtpxMQOC3VTcKHahSEG6akFXBuKDbsRXbVpsEautUzRTU9tM7r3xnnse99zXedwIKvhCUv3zW/w3v+/7wPV7z6+NP746PpO7O5d/sJCfWLxVeHVj8mXuwYubky8nFoyH8/rEi2r9WTDuPtPvz1enFo3C3PLUnQIYOHth29Y/IpFIJpMZGRn56+TJ4ydOtLe3RyKRVGr/UmlleaVSrlSNt9CA9uIb/EZngQj9QDLHB3+fPgMA2PDrb/F4fHh4uKurK5lM7ty5CwAQb9279HpltawVi69NiygVKqWklJxLKSVmPuj/ZyC6fVssFmtsbOzu7k6n052dnR0dHbHYjiNHjupVaGK3rCNIfSml+sFajO1wcGrwXHPT7kOH69fW1tbb2zs2NpbP5/v7+//M9lQqmq5rlmW5XsCcwHE5cwKbub7nYeqBs0OX1/+y7lg6nc1mAQB9fX25XG50dDSVSu1LJCtaVdN00yK65VWhDbEDEUOEEUIgZuD8pStbft984ODBnp6eRCIxODg4NDSUzWYbGhpa9sTLGiwbpGxYns9DJdUPZH02dQJw7uKVTRs3NjU1ZzKZRCLZ2tra3NKyPRoFAESjsf+WSm/1ykKxXFxFoRQ8EILXkUIQ5oPC09Lt8Znpmfn5xVdzz/+dffKsMD07OTXz8NH07JM5ExKMYOl/VEGiFkohpFxDKVmXTRZ8+/pZKUUp9VyHB4HnumEYfvn86f2Hj9DCEMJQipoSvF5Y37zmC8ICoCFHSel6LqG0tGoUlzULYcdxhBCezy1s67pumtA0oVGtIoSYbbuuq+Ras47dd7VQSMU5J9ShLg+44JyHoeJcUsowQggRzwtsSjFG0DRtajObQuz8lLmQnu8LzsNaLQj4T1lIiziEMgtTiAjClFBmM4e5PucS2f53VkQnwslhbucAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8fc57039a191eb41830cdca16580c456/e4706/YAWcam_09.avif 230w", "/en/static/8fc57039a191eb41830cdca16580c456/d1af7/YAWcam_09.avif 460w", "/en/static/8fc57039a191eb41830cdca16580c456/04d72/YAWcam_09.avif 701w"],
              "sizes": "(max-width: 701px) 100vw, 701px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/8fc57039a191eb41830cdca16580c456/a0b58/YAWcam_09.webp 230w", "/en/static/8fc57039a191eb41830cdca16580c456/bc10c/YAWcam_09.webp 460w", "/en/static/8fc57039a191eb41830cdca16580c456/e2a71/YAWcam_09.webp 701w"],
              "sizes": "(max-width: 701px) 100vw, 701px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8fc57039a191eb41830cdca16580c456/81c8e/YAWcam_09.png 230w", "/en/static/8fc57039a191eb41830cdca16580c456/08a84/YAWcam_09.png 460w", "/en/static/8fc57039a191eb41830cdca16580c456/49217/YAWcam_09.png 701w"],
              "sizes": "(max-width: 701px) 100vw, 701px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/8fc57039a191eb41830cdca16580c456/49217/YAWcam_09.png",
              "alt": "Windows YAW Cam",
              "title": "Windows YAW Cam",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Use the motion detection to trigger actions like email notifications, FTP uploads or video recordings.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      